import { defineChain } from 'viem';

export const botanixTestnet = defineChain({
	id: 3636,
	name: 'Botanix Testnet',
	nativeCurrency: { name: 'BTC', symbol: 'BTC', decimals: 18 },
	rpcUrls: {
		default: {
			http: ['https://node.botanixlabs.dev'],
		},
	},
	blockExplorers: {
		default: {
			name: 'Botanix Testnet Explorer',
			url: 'https://testnet.botanixscan.io/',
		},
	},
	testnet: true,
});
