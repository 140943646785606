import { useMemo, useState } from 'react';
import { updateCampaignMetadata } from '../../services/campaigns.service';
import { useCampaignReport } from './useCampaignReport';
import { uploadFile } from '@/services/utility.service';
import { queryClient } from '@/lib/react-query';
import {
	CampaignRewardCategory,
	ICampaign,
	IdentityNamespaceTag,
	Status,
} from '../../types';
import { toast } from 'sonner';
import analytics from '@/lib/analytics';
import { TrackingEvents } from '@/types/tracking.type';

export const useEditCamapignMetadata = (setOpen: any) => {
	const { campaign } = useCampaignReport();
	const [isLoading, setIsLoading] = useState(false);

	const [details, setDetails] = useState({
		name: campaign?.name,
		description: campaign?.description,
		banner: campaign?.banner,
		bannerFile: null,
		network: campaign?.tags
			? campaign?.tags.find((tag) => tag.key === 'chainId')?.value
			: '0',
		chain: campaign?.tags
			? campaign?.tags.find((tag) => tag.key === 'chain')?.value
			: null,
		namespaceTag: campaign?.tags
			? campaign?.tags.find((tag) => tag.key === 'namespaceTag')?.value
			: null,
		seoMetadata: {
			title: campaign?.seoMetadata?.title,
			description: campaign?.seoMetadata?.description,
			image: campaign?.seoMetadata?.image,
			imageFile: null,
		},
		spotlight:
			campaign?.narrativeTexts?.length > 0
				? campaign?.narrativeTexts[0].text
				: '',
		twitterShare: campaign?.twitterShare?.text,
		bannerPosition: {
			x: campaign?.bannerPosition?.x || 0,
			y: campaign?.bannerPosition?.y || 0,
		},
		endDate: campaign?.endDate,
		timezone: campaign?.endDateTz,
		narrativeTexts: campaign?.narrativeTexts || [],
	});
	const [errors, setErrors] = useState({
		name: false,
		description: false,
		banner: false,
		endDate: false,
	});

	const canEditEndDate = useMemo(() => {
		return (
			campaign?.status === Status.Active &&
			campaign?.reward?.filter((reward) => reward.category === 'TOKEN')
				.length === 0
		);
	}, [campaign]);

	const handleDetailsEdit = (key: string, value) => {
		setDetails((prev) => ({
			...prev,
			[key]: value,
		}));
		setErrors((prev) => ({
			...prev,
			[key]: false,
		}));
	};

	const handleChainChange = ({
		chainId,
		chain,
		namespaceTag,
	}: {
		chainId: string;
		chain: string;
		namespaceTag: IdentityNamespaceTag;
	}) => {
		setDetails((prev) => ({
			...prev,
			network: chainId,
			chain: chain,
			namespaceTag: namespaceTag,
		}));
		setErrors((prev) => ({
			...prev,
			network: false,
		}));
	};

	const validateMetadata = () => {
		const newErrors = {
			name: false,
			description: false,
			banner: false,
			endDate: false,
		};
		if (!details.name) {
			newErrors.name = true;
		}
		if (!details.description) {
			newErrors.description = true;
		}
		if (!details.endDate) {
			newErrors.endDate = true;
		}
		setErrors(newErrors);
		return !Object.values(newErrors).some((error) => error);
	};

	const updateMetadata = async () => {
		try {
			if (!validateMetadata()) return;
			setIsLoading(true);
			await updateCampaignMetadata(campaign._id, {
				name: details.name,
				description: details.description,
				network: details.network,
				chain: details.chain,
				namespaceTag: details.namespaceTag,
				endDate: details.endDate,
				narrativeTexts: details.narrativeTexts || [],
			});
			analytics.track(TrackingEvents.QuestDetailsUpdated, {
				campaignId: campaign._id,
				campaignName: details.name,
			});
			queryClient.invalidateQueries({
				queryKey: ['campaign', campaign._id],
			});
			const existingTableData = queryClient.getQueryData([
				'campaigns',
			]) as ICampaign[];
			if (existingTableData) {
				queryClient.setQueryData(
					['campaigns'],
					(existingTableData as any[]).map((item) => {
						if (item._id === campaign._id) {
							let tags = [];
							if (item.tags) {
								tags = item.tags.filter(
									(tag) => tag.key !== 'chainId',
								);
							}
							return {
								...item,
								name: details.name,
								description: details.description,
								tags: [
									...tags,
									{
										key: 'chainId',
										value: details.network,
									},
									{
										key: 'chain',
										value: details.chain,
									},
									{
										key: 'namespaceTag',
										value: details.namespaceTag,
									},
								],
							};
						}
						return item;
					}),
				);
			}
			toast.success('Campaign Details updated successfully');
			setOpen?.(false);
			setIsLoading(false);
		} catch (err) {
			console.log(err);
			setIsLoading(false);
		}
	};
	const updateBanner = async () => {
		try {
			setIsLoading(true);
			let fileLink = details.banner;
			if (details.bannerFile) {
				const file = await uploadFile(details.bannerFile);
				fileLink = file;
				await updateCampaignMetadata(campaign._id, {
					banner: file,
					bannerPosition: details.bannerPosition,
				});
			} else {
				await updateCampaignMetadata(campaign._id, {
					bannerPosition: details.bannerPosition,
				});
			}
			analytics.track(TrackingEvents.QuestBannerUpdated, {
				campaignId: campaign._id,
				campaignName: details.name,
			});
			const existingTableData = queryClient.getQueryData([
				'campaign',
				campaign._id,
			]) as ICampaign;
			if (existingTableData) {
				queryClient.setQueryData(['campaign', campaign._id], {
					...existingTableData,
					banner: fileLink,
					bannerPosition: details.bannerPosition,
				});
			}
			toast.success('Banner updated successfully');
			setIsLoading(false);
			setOpen(false);
		} catch (err) {
			setIsLoading(false);
		}
	};

	const isNFTCampaign = useMemo(() => {
		return (
			campaign?.reward?.filter(
				(reward) => reward.category === CampaignRewardCategory.Nft,
			).length > 0
		);
	}, [campaign?.reward]);

	return {
		details,
		errors,
		setDetails,
		setErrors,
		handleDetailsEdit,
		updateMetadata,
		isLoading,
		setIsLoading,
		updateBanner,
		endDate: campaign?.endDate,
		canEditEndDate,
		handleChainChange,
		isNFTCampaign,
	};
};
