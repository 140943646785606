import { useEffect, useState } from 'react';
import { RouteObject, useRoutes } from 'react-router-dom';
import { authRoutes } from './auth';
// import { publicRoutes } from './public';
import { protectedRoutes } from './protected';
import { useAuth } from '@/hooks/useAuth';

import { onboardingRoutes } from './onboarding';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { adminRoutes } from './admin';
import { useMemo } from 'react';

function AppRoutes() {
	const { user } = useAuth();
	const [routes, setRoutes] = useState<RouteObject[]>([]);

	const needsOnboarding = useMemo(() => {
		if (!user || !user.enterprises || user.enterprises.length === 0)
			return false;
		const currentEnterprise = user.enterprises.find(
			(e) => e?._id === user.enterpriseId,
		);
		return !!currentEnterprise && !currentEnterprise?.name;
	}, [user]);

	useEffect(() => {
		let newRoutes = [];

		if (!user) {
			console.log('I am here: No user');
			newRoutes = [...authRoutes];
		} else if (user.isIntractAdmin) {
			newRoutes = [...protectedRoutes, ...adminRoutes];
		} else if (needsOnboarding) {
			newRoutes = [...onboardingRoutes];
		} else {
			newRoutes = [...protectedRoutes];
		}

		console.log(newRoutes);
		setRoutes(newRoutes);
	}, [user, needsOnboarding]);

	if (!routes.length) {
		return <div>Loading...</div>;
	}

	return (
		<RouterProvider
			fallbackElement={<div>Loading...</div>}
			router={createBrowserRouter(routes)}
		/>
	);
}

export default AppRoutes;
