import { defineChain } from 'viem';

export const abstractMainnet = defineChain({
	id: 2741,
	name: 'Abstract Mainnet',
	nativeCurrency: { name: 'ETH', symbol: 'ETH', decimals: 18 },
	rpcUrls: {
		default: {
			http: ['https://api.mainnet.abs.xyz'],
			webSocket: ['wss://api.mainnet.abs.xyz/ws'],
		},
	},
	blockExplorers: {
		default: {
			name: 'Abstract Mainnet Explorer',
			url: 'https://api.abscan.org/api',
		},
	},
	testnet: false,
});
