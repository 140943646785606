import { Separator } from '@/components/ui/separator';
import logo from '@/assets/logo.svg';
import { Button } from '@/components/ui/button';
import { useLocation } from 'react-router-dom';
import { useRouter } from '@/hooks/useRouter';

const Footer = () => {
	const location = useLocation();
	return (
		<footer className="border-t border-gray-200">
			<div className="mx-auto w-full max-w-screen-xl px-2.5 lg:px-20 mb-6">
				<div className="my-14 w-full">
					<SignUpCTA />
				</div>
				<Separator className="my-10" />
				<FooterLinks />
				<Separator className="my-5" />
				<CopyWrite />
			</div>
		</footer>
	);
};

export default Footer;

const StarIcon = () => {
	return <span className="h3 mx-3 text-zinc-400">✦</span>;
};

const SignUpCTA = () => {
	const { navigate } = useRouter();
	return (
		<div className="flex flex-col lg:flex-row gap-6 lg:items-center items-start justify-between w-full">
			<div className="flex flex-col gap-6 md:flex-row md:items-center justify-between md:justify-between w-full px-2 md:px-0">
				<div className="w-full flex flex-col gap-3">
					<a href="/" className="w-fit flex items-center gap-3">
						<picture>
							<img
								src={logo}
								className="size-8 aspect-square object-center object-contain"
								loading="lazy"
								decoding="async"
								alt="intract-logo"
							/>
						</picture>
						<img
							src="https://static.highongrowth.xyz/enterprise/662909974869afabb43349eb/90170292ce2344b6a3ea1296f80a9f0c.png"
							alt="Intract name logo"
							className="h-5 w-auto"
							loading="lazy"
							decoding="async"
						/>
						{/* <p className="text-2xl font-medium ml-2">intract</p> */}
					</a>
					<p className="text-muted-foreground text-sm">
						We are your personal guide for exploring web3 projects &
						earning 100x rewards
					</p>
				</div>
				<div className="flex w-full md:justify-end">
					<Button
						size="lg"
						className="rounded-xl text-base h-12"
						onClick={() => {
							navigate('/register');
						}}
					>
						Create Quest
					</Button>
				</div>
			</div>
		</div>
	);
};

const CopyWrite = () => {
	return (
		<div className="flex flex-row gap-4 items-center justify-between text-black-500">
			<div className="flex  items-center gap-2">
				<div className="size-5 text-muted-foreground">
					<svg
						width="100%"
						height="100%"
						viewBox="0 0 24 24"
						fill="inherit"
						xmlns="http://www.w3.org/2000/svg"
						className="fill-current"
					>
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M11.8673 4H12.1327C12.8071 8.02 15.98 11.1928 20 11.8673V12.1327C15.98 12.8072 12.8071 15.98 12.1327 20H11.8673C11.1929 15.98 8.02001 12.8072 4 12.1327V11.8673C8.02001 11.1928 11.1929 8.02 11.8673 4Z"
						/>
					</svg>
					<span className="sr-only">Little Star</span>
				</div>
				<span className="text-sm text-muted-foreground">
					Copyright © 2024 Intract. All rights reserved.
				</span>
			</div>
			<div className="inline-flex gap-2 items-center">
				<a
					href="https://x.com/IntractCampaign"
					target="_blank"
					className="btn-size-5 btn-tertiary"
				>
					<div className="">
						<i className="bi-twitter-x text-black/70 text-lg"></i>
						<span className="sr-only">Twitter</span>
					</div>
					<span className="sr-only">Twitter</span>
				</a>
				<span className="small text-regular text-black/70">/</span>
				<a
					href="https://discord.com/invite/faXj8Yzua6/"
					target="_blank"
					className="btn-size-5 btn-tertiary"
				>
					<div className="">
						<i className="bi-discord text-black/70 text-lg"></i>
						<span className="sr-only">Discord</span>
					</div>
					<span className="sr-only">Discord</span>
				</a>
				<span className="small text-regular text-black/70">/</span>
				<a
					href="https://t.me/+IntractOfficial/"
					target="_blank"
					className="btn-size-5 btn-tertiary"
				>
					<div className="">
						<i className="bi-telegram text-black/70 text-lg"></i>
						<span className="sr-only">Telegram</span>
					</div>
					<span className="sr-only">Telegram</span>
				</a>
				<span className="small text-regular text-black/70">/</span>
				<a
					href="https://open.spotify.com/show/40sCgrg26jFDN3i3WsHB5D"
					target="_blank"
					className="btn-size-5 btn-tertiary"
				>
					<div className="">
						<i className="bi-spotify text-black/70 text-lg"></i>
						<span className="sr-only">Spotify</span>
					</div>
					<span className="sr-only">Spotify</span>
				</a>
			</div>
		</div>
	);
};

const FooterLinks = () => {
	return (
		<div className="my-10 mx-10 lg:mx-0">
			<div className="grid grid-cols-2 md:grid-cols-4 gap-10">
				{footerLinks.map((group) => (
					<FooterLinksGroup {...group} key={group.title} />
				))}
			</div>
		</div>
	);
};

const FooterLinksGroup = ({ title, links }) => {
	return (
		<div>
			<h3 className="text-sm font-semibold text-gray-800">{title}</h3>
			<ul role="list" className="mt-4 space-y-2">
				{links.map((i) => (
					<li>
						<a
							className="text-sm text-gray-500 hover:text-gray-800"
							href={i.href}
							target="_blank"
						>
							{i.title}
						</a>
					</li>
				))}
			</ul>
		</div>
	);
};

const footerLinks = [
	{
		title: 'Intract',
		links: [
			{
				title: 'Explore Quests',
				href: 'https://www.intract.io/explore?hideCompleted=true&hideExpired=true&sortBy=participation',
			},
			{
				title: 'Communities',
				href: 'https://www.intract.io/explore?entity_type=COMMUNITY&official=true&activeCampaign=true&sortBy=participation',
			},
			{
				title: 'Alpha Hub',
				href: 'https://www.intract.io/alpha-hub',
			},
		],
	},
	{
		title: 'Earn',
		links: [
			{
				title: 'Refer and Earn',
				href: 'https://www.intract.io/referral',
			},
			{
				title: 'Leaderboard',
				href: 'https://www.intract.io/leaderboard',
			},
			{
				title: 'Achievements',
				href: 'https://www.intract.io/achievements',
			},
		],
	},
	{
		title: 'About',
		links: [
			{
				title: 'Product Roadmap',
				href: 'https://docs.intract.io/',
			},
			{
				title: 'Affiliate Program',
				href: 'https://www.intract.io/affiliate-program',
			},
			{
				title: 'Sign up Program',
				href: 'https://docs.intract.io/for-business/sign-up-program',
			},
			{
				title: 'Growth Community',
				href: 'https://docs.google.com/forms/d/e/1FAIpQLSeNjPMdJYsCPyGnCcaEHgSceiTimig8xzHVE_bokbBOU_chIQ/viewform',
			},
			{
				title: 'Blogs',
				href: 'https://www.intract.io/blogs',
			},
		],
	},
	{
		title: 'Support',
		links: [
			{
				title: 'Help Center',
				href: 'https://intract-io.notion.site/Intract-Quests-Campaigns-FAQ-6ac66e1a71374f0b9d1f90fe92c7928f?pvs=4',
			},
			{
				title: 'Terms of Service',
				href: 'https://intract.gitbook.io/website-policies/intract-terms-of-use',
			},
			{
				title: 'Privacy Policy',
				href: 'https://intract.gitbook.io/website-policies/intract-privacy-policy',
			},
			{
				title: 'Community Guidelines',
				href: 'https://intract.gitbook.io/website-policies/intract-community-guidelines?allow=true',
			},
		],
	},
];
