import { defineChain } from 'viem';

export const soneium = defineChain({
	id: 1868,
	name: 'Soneium',
	nativeCurrency: { name: 'ETH', symbol: 'ETH', decimals: 18 },
	rpcUrls: {
		default: {
			http: ['https://rpc.soneium.org'],
		},
	},
	blockExplorers: {
		default: {
			name: 'Soneium Explorer',
			url: 'https://soneium.blockscout.com/',
		},
	},
	testnet: false,
});
