export const homepageContent = {
	hero: {
		title: 'Acquire 12M+ Real Web3 Users',
		description:
			'Unleash your Community Growth with 100x Co-Marketing Boost from Intract. Sign Up Now to enjoy $10K Credits for Free',
		badge: {
			label: 'Intract Raises $15.2M to Fuel Growth Plans in 2024',
			link: '',
		},
		cta: {
			primary: {
				link: '/register',
				label: 'Start Now',
			},
			secondary: {
				link: 'https://intract.io/explore',
				label: 'Explore Trending Projects',
			},
		},
	},
	metrics: [
		{
			metric: '12M+',
			title: 'ReaL Web3 Users',
		},
		{
			metric: '2,200+',
			title: 'KOLs',
		},
		{
			metric: '10,500+',
			title: 'Enterprise Partners',
		},
	],
	companies: {
		title: 'Giving superpowers to modern teams at world-class companies',
		logos: [
			{
				img: 'https://static.highongrowth.xyz/enterprise/660ed0280f5e5a9e8238d0f5/1fb9fc6d2dbf42ff9f492d9082e0a1aa.png',
				name: 'mode',
			},
			{
				img: 'https://static.highongrowth.xyz/enterprise/660ed0280f5e5a9e8238d0f5/732909fe67794b33afa3b0c4bd7a13f5.png',
				name: 'polygon',
			},
			{
				img: 'https://static.highongrowth.xyz/enterprise/660ed0280f5e5a9e8238d0f5/5ceaff06f62840269725d7ebb4644a6d.png',
				name: 'base',
			},
			{
				img: 'https://static.highongrowth.xyz/enterprise/660ed0280f5e5a9e8238d0f5/297a01211ba3430f94f304032d523b54.png',
				name: 'blast',
			},
			{
				img: 'https://static.highongrowth.xyz/enterprise/660ed0280f5e5a9e8238d0f5/2976325a79544e5882fa76cae86a1716.png',
				name: 'linea',
			},
			// {
			// 	img: 'https://static.highongrowth.xyz/enterprise/660ed0280f5e5a9e8238d0f5/328ebe6dbd1d4519bf023b302df43ace.png',
			// 	name: 'zksync',
			// },
			{
				img: 'https://static.highongrowth.xyz/enterprise/662909974869afabb43349eb/34c6ef5ebc524b109d4df9c7f6954e11.svg',
				name: 'berachain',
			},
			{
				img: 'https://static.highongrowth.xyz/enterprise/662909974869afabb43349eb/ecae5ee33c344608a28339d6f9d379b8.svg',
				name: 'starknet',
			},
			{
				img: 'https://static.highongrowth.xyz/enterprise/662909974869afabb43349eb/dd9f9383f6c3402c9f0c2f2dc4683c16.svg',
				name: 'bitcoin',
			},
			{
				img: 'https://static.highongrowth.xyz/enterprise/662909974869afabb43349eb/ce5167574a8b4beaa2d00ed6e59639b0.svg',
				name: 'binance',
			},
			{
				img: 'https://static.highongrowth.xyz/enterprise/662909974869afabb43349eb/8de79a9ec2204b2e94bcb5a6764052ab.svg',
				name: 'solana',
			},
		],
	},
	features: {
		title: 'Powerful analytics for Web3 and the modern marketer',
		description: `There is a reason we are called World’s No. 1 Questing Platform. We've built a suite of powerful features that gives you marketing superpowers. `,
		featureList: [
			{
				emoji: '✨',
				title: 'Intuitive Quest Creation Process',
			},
			{
				emoji: '🎉',
				title: 'Engaging Loyalty and Rewards Program',
			},
			{
				emoji: '🧩',
				title: 'Simplified API and On-Chain Verification',
			},
			{
				emoji: '📈',
				title: 'Advanced User Analytics and Data Export',
			},
			{
				emoji: '🔥',
				title: 'Access to Influential KOLs and Partners',
			},
		],
		coreFeatures: [
			{
				id: '1',
				icon: 'people',
				title: 'Intuitive Quest Creation Process',
				description: `Create quests easily with customizable task templates. Efficient, user-friendly design for task creation.`,
				cta: {
					link: '/features',
					label: 'Learn more',
				},
				image: 'https://static.highongrowth.xyz/enterprise/65eef52ec069007c5d70d362/3eb6e6b6597a434488acf186487c7dec.jpg',
				video: 'https://assets.dub.co/features/powerful-analytics.mp4',
			},
			{
				id: '2',
				icon: 'people',
				title: 'Engaging Loyalty and Rewards Program',
				description: `Keep users coming back with a dynamic loyalty system and reward them for their efforts.`,
				cta: {
					link: '/features',
					label: 'Learn more',
				},
				image: 'https://static.highongrowth.xyz/enterprise/65eef52ec069007c5d70d362/a72cfc2a494a4f019dcf74614af22ffb.png',
				video: 'https://assets.dub.co/features/powerful-analytics.mp4',
			},
			{
				id: '3',
				icon: 'people',
				title: 'Simplified API and On-Chain Verification',
				description: `Easily integrate APIs and leverage no code infrastructure for on-chain transaction verification.`,
				cta: {
					link: '/features',
					label: 'Learn more',
				},
				image: 'https://static.highongrowth.xyz/enterprise/65eef52ec069007c5d70d362/689b4bf4c7924062982b88e118ec1619.png',
				video: 'https://assets.dub.co/features/powerful-analytics.mp4',
			},
			{
				id: '4',
				icon: 'people',
				title: 'Advanced User Analytics and Data Export',
				description: `Gain detailed insights into user behavior. Export data effortlessly for further analysis.`,
				cta: {
					link: '/features',
					label: 'Learn more',
				},
				image: 'https://static.highongrowth.xyz/enterprise/65eef52ec069007c5d70d362/a06c280731bc44a8b2ec15d64f999a8f.png',
				video: 'https://assets.dub.co/features/powerful-analytics.mp4',
			},
			{
				id: '5',
				icon: 'people',
				title: 'Access to Influential KOLs and Partners',
				description: `Leverage our expansive network of key opinion leaders and partners. Enhance outreach and user engagement.`,
				cta: {
					link: '/features',
					label: 'Learn more',
				},
				image: 'https://static.highongrowth.xyz/enterprise/65eef52ec069007c5d70d362/47bfabf41e41446783fdd47db801fc1b.png',
				video: 'https://assets.dub.co/features/powerful-analytics.mp4',
				author: 'https://assets.dub.co/author/steventey.jpg',
			},
		],
	},
	valueProps: [
		{
			title: 'Instant Access to 12 million On-Chain Users',
			description:
				'Connect with 12 million verified on-chain wallets in just 10 minutes.',
			image: 'https://static.highongrowth.xyz/enterprise/667bbfbc010a0189758803ae/976a538edb424d27ad475b278a9749c6.svg',
		},
		{
			title: 'One-Stop solution for Acquisition, Engagement, and Rewards',
			description:
				'A single product solution that covers acquisition, engagement, and rewards. Simplify your workflow with all-in-one functionality.',
			image: 'https://static.highongrowth.xyz/enterprise/667bbfbc010a0189758803ae/f5eb09b98d134744b2a30ab79d11553a.svg',
		},
		{
			title: 'Enhanced Performance Utilities',
			description:
				'Utilize influential KOLs and strategic partners to boost performance. Enhance your outreach and effectiveness with key resources.',
			image: 'https://static.highongrowth.xyz/enterprise/667bbfbc010a0189758803ae/9eca754a625a485ea0f1137979453986.svg',
		},
	],
	testimonials: {
		title: 'What our **Partners are Saying**',
		description:
			'Here’s how our 10,500+ partners use Intract to take their communities to the next level.',
		titleGradient: 'pink',
	},
};

export const testimonials = [
	{
		companyLogo:
			'https://static.highongrowth.xyz/enterprise/660ed0280f5e5a9e8238d0f5/79355ab41ee8446eac266fed18da983f.svg',
		testimonial: `I honestly like Intract a lot better than the other quest platforms, the UX and flow are a lot more intuitive. Working with them is just another level. Less chit chat and more real delivery. The team rocks. 🔥`,
		name: 'ETH L2',
		profile: 'Founding Member',
		backgroundVariant: 'gradient-purple',
		isDark: false,
		heading: '$1B+ TVL growth',
	},
	{
		companyLogo:
			'https://static.highongrowth.xyz/enterprise/660ed0280f5e5a9e8238d0f5/3c3dacc780a2454898b60afa37c30c60.svg',
		testimonial: `Intract powered our first campaign and played a key role in onboarding tons of early adopters onto our platform 🚀 I love their onchain verifications which eliminate any manual processed involved in running a campaign. The team is super helpful in setting up campaigns ✨`,
		name: 'Crypto x AI Memecoin',
		profile: 'Founder',
		backgroundVariant: 'gradient-pink',
		isDark: false,
		heading: 'Viral memecoin launch',
	},
	{
		companyLogo:
			'https://static.highongrowth.xyz/enterprise/660ed0280f5e5a9e8238d0f5/4e4206fedad64a7dbbc50c4008e11e13.svg',
		testimonial: `Intract stands as a key partner in our community engagement initiatives. We are thrilled by their innovative ideas and execution 🚀🚀🚀 <br/><br />Collaborating with them has been a rewarding experience 🔥`,
		name: 'DeFi Bridge',
		profile: 'Marketing Lead',
		backgroundVariant: 'gradient-black',
		isDark: false,
		heading: '$100M+ bridged volumes',
	},
	{
		companyLogo:
			'https://static.highongrowth.xyz/enterprise/660ed0280f5e5a9e8238d0f5/6f003dbc5f7049a393f76c6c6291e5b3.svg',
		testimonial: `Intract team was super helpful while we were going through our first campaign. It got a lot of real attention and helped put us on the map. We’ll definitely launch another Intract campaign soon. 💎`,
		name: 'Web3 Wallet',
		profile: 'Founder',
		backgroundVariant: 'gradient-blue',
		isDark: false,
		heading: '50,000+ users acquired',
	},
	{
		companyLogo:
			'https://static.highongrowth.xyz/enterprise/660ed0280f5e5a9e8238d0f5/79355ab41ee8446eac266fed18da983f.svg',
		testimonial: `The Intract team has been incredibly helpful at every step of the process. Any questions we had and any modifications we had to make were swiftly answered and help was provided almost immediately. The team also stepped up and sent speakers over to our Twitter spaces who have been incredibly insightful and their input is highly valued. 🚀🚀🚀`,
		name: 'DePIN Mobile App',
		profile: 'Chief Strategy Officer',
		backgroundVariant: 'gradient-purple',
		isDark: false,
		heading: '80+ ecosystem partnerships',
	},
	{
		companyLogo:
			'https://static.highongrowth.xyz/enterprise/660ed0280f5e5a9e8238d0f5/3c3dacc780a2454898b60afa37c30c60.svg',
		testimonial: `Our partnership with Intract has been exceptional. 💎 We've launched over 6 successful campaigns with them at Rango, attracting numerous users on Twitter, Discord, and for cross-chain swapping. Looking forward to more successes with Intract! 🔥`,
		name: 'DeFi Exchange',
		profile: 'Head of BD',
		backgroundVariant: 'gradient-pink',
		isDark: false,
		heading: '$100M+ traded volumes',
	},
	{
		companyLogo:
			'https://static.highongrowth.xyz/enterprise/660ed0280f5e5a9e8238d0f5/4e4206fedad64a7dbbc50c4008e11e13.svg',
		testimonial: `Love the Intract team’s approach. Intract was the first quest platform to expand to Bitcoin and the team went above and beyond to help us build our community. 🦾`,
		name: 'Bitcoin Runes NFT Collection',
		profile: 'Founder',
		backgroundVariant: 'gradient-black',
		isDark: false,
		heading: 'NFT mint sold-out',
	},
	{
		companyLogo:
			'https://static.highongrowth.xyz/enterprise/660ed0280f5e5a9e8238d0f5/6f003dbc5f7049a393f76c6c6291e5b3.svg',
		testimonial: `Experience with Intract has been quite great. The entire experience was smooth and straight-forward. I was really surprised by the advanced analytics dashboard. The ability to check all the stats was on point. Everything that was promised was delivered. 🔥`,
		name: 'RWA Protocol',
		profile: 'Business Developer',
		backgroundVariant: 'gradient-blue',
		isDark: false,
		heading: 'Comprehensive user analytics',
	},
	{
		companyLogo:
			'https://static.highongrowth.xyz/enterprise/660ed0280f5e5a9e8238d0f5/79355ab41ee8446eac266fed18da983f.svg',
		testimonial: `We had the Intract team helping us a lot with the operational and technical part. 💎 I’d like to highlight first the support we’re getting is exceptional. Samriti from Intract is super tech-savvy, helpful and kind. Even minor issues, she’s always there to help me. Second thing I want to talk about is the super easy user experience. For both users and project creators. Last point, the engagement we got from Intract was organic and not botted. This is a major edge Intract offers. 🚀`,
		name: 'Web3 Gaming Accelerator',
		profile: 'Chief of Staff',
		backgroundVariant: 'gradient-purple',
		isDark: false,
		heading: '50,000+ gamers acquired',
	},
];
