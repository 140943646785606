import GlobalLayout from '@/components/layout/GlobalLayout';
import AuthRoutes from '@/features/auth/routes/auth.route';
import { Navigate } from 'react-router-dom';

export const authRoutes = [
	{
		path: '/*',
		element: (
			<GlobalLayout>
				<AuthRoutes />
			</GlobalLayout>
		),
	},
	{
		path: '*',
		element: <Navigate_ />,
	},
];

function Navigate_() {
	return <Navigate to={`${window.location.search}`} />;
}
