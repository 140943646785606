import { defineChain } from 'viem';

export const unitZeroMainnet = defineChain({
	id: 88811,
	name: 'Unit Zero Mainnet',
	nativeCurrency: {
		decimals: 8,
		name: 'UNIT0',
		symbol: 'UNIT0',
	},
	rpcUrls: {
		default: { http: ['https://rpc.unit0.dev'] },
	},
	blockExplorers: {
		default: {
			name: 'Unit Zero Explorer',
			url: 'https://explorer.unit0.dev/',
		},
	},
	testnet: false,
});
