import { defineChain } from 'viem';

export const plumeChain = defineChain({
	id: 98865,
	name: 'Plume Mainnet',
	nativeCurrency: { name: 'ETH', symbol: 'ETH', decimals: 18 },
	rpcUrls: {
		default: {
			http: ['https://rpc.plumenetwork.xyz'],
		},
	},
	blockExplorers: {
		default: {
			name: 'Plume Mainnet Explorer',
			url: 'https://explorer.plumenetwork.xyz/',
		},
	},
	testnet: false,
});
