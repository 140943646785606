import TooltipWrapper from '@/components/element/tooltips/TooltipWrapper';
import AuthLayout2 from '@/components/layout/auth/AuthLayout2';
import {
	Accordion,
	AccordionContent,
	AccordionItem,
	AccordionTrigger,
} from '@/components/ui/accordion';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { links } from '@/config/links';
import { useRouter } from '@/hooks/useRouter';
import analytics from '@/lib/analytics';
import { cn } from '@/lib/utils';
import { TrackingEvents } from '@/types/tracking.type';
import { useEffect, useState } from 'react';
import en from '../../../locales/en.json';
import { useRegister } from '../hooks/useRegister';
import Navbar from '@/features/landing/components/layout/Navbar';

export default function RegisterPage() {
	const {
		handleSubmit,
		onSubmit,
		register,
		isLoading,
		loginWithDiscord,
		loginWithGoogle,
		errors,
	} = useRegister();
	const { query } = useRouter();
	const { registerPage: registerTypography } = en;

	const [openAccordion, setOpenAccordion] = useState('');
	const [showPassword, setShowPassword] = useState(false);

	const handleAccordionChange = (value: string) => {
		if (openAccordion !== value) {
			analytics.track(TrackingEvents.CreditsExpandRegisterPage, {});
		}
		setOpenAccordion((prevValue) => (prevValue === value ? null : value));
	};

	useEffect(() => {
		if (query.referralCode) {
			localStorage.setItem('referral-code', query.referralCode as string);
		}
	}, [query]);

	useEffect(() => {
		analytics.track(TrackingEvents.SignUpClicked, {});
	}, []);

	return (
		<div>
			<Navbar />
			<div className="container flex h-[90vh] w-screen flex-col items-center justify-center">
				<div className="mx-auto flex w-full flex-col justify-center space-y-6 sm:w-[350px]">
					<div className="flex flex-col space-y-2 text-center">
						<h1 className="text-2xl font-semibold tracking-tight">
							Create an account
						</h1>
						<p className="text-sm text-muted-foreground">
							Enter your email below to create your account
						</p>
					</div>
					<div className="grid gap-6">
						<form onSubmit={handleSubmit(onSubmit)}>
							<div className="grid gap-2">
								<div className="grid gap-1 mb-2">
									{/* <Label className="font-normal" htmlFor="name">
									Name
								</Label>
								<Input
									id="name"
									placeholder="Cartman"
									autoCorrect="off"
									{...register('name', { required: true })}
								/> */}
									<Label
										className="font-normal mt-4"
										htmlFor="email"
									>
										Work Email
										<TooltipWrapper tooltip="A work email ensures a higher success rate of project approval. Personal emails are discouraged.">
											<i className="bi-info-circle ms-1 text-muted-foreground text-xs"></i>
										</TooltipWrapper>
									</Label>
									<Input
										id="email"
										placeholder="name@company.com"
										type="email"
										autoCapitalize="none"
										autoComplete="email"
										autoCorrect="off"
										{...register('email', { required: true })}
										className={
											errors.email ? 'border-destructive' : ''
										}
									/>
									{errors.email && (
										<p className={'text-xs text-destructive'}>
											{errors.email.message}
										</p>
									)}
									<Label
										className="mt-4 font-normal"
										htmlFor="password"
									>
										Password
									</Label>
									<div className="relative">
										<Input
											id="password"
											placeholder="8+ characters required"
											type={showPassword ? 'text' : 'password'}
											autoCapitalize="none"
											autoCorrect="off"
											{...register('password', {
												required: true,
											})}
											className={
												errors.password
													? 'border-destructive'
													: ''
											}
										/>
										<span
											className="absolute right-2 top-2 cursor-pointer text-muted-foreground"
											onClick={() =>
												setShowPassword((prev) => !prev)
											}
										>
											<i
												className={`bi-${showPassword ? 'eye-slash' : 'eye'}`}
											></i>
										</span>
									</div>
									{errors.password && (
										<p className={'text-xs text-destructive'}>
											{errors.password.message}
										</p>
									)}
									<Accordion
										type="single"
										collapsible
										className="w-full"
										value={openAccordion}
									>
										<AccordionItem value="creditsInfo">
											<AccordionTrigger
												className="text-left font-normal text-sm focus:ring-0 focus:outline-none focus:border-none focus-visible:ring-0 focus-visible:outline-none"
												onClick={() =>
													handleAccordionChange(
														'creditsInfo',
													)
												}
											>
												{
													registerTypography?.claimCredits
														?.title
												}
											</AccordionTrigger>
											<AccordionContent className="text-gray-500 transition-all pt-0.5">
												<ul className=" list-inside space-y-2">
													{Array.isArray(
														registerTypography
															?.claimCredits?.list,
													) &&
														registerTypography?.claimCredits?.list?.map(
															(item, index) => (
																<li
																	key={index}
																	className="text-xs text-slate-500 leading-[18px]"
																>
																	<span
																		className={cn(
																			'bg-slate-100 rounded-[20px] py-0.5 me-2',
																			index ===
																				0
																				? 'px-[7px]'
																				: 'px-1.5',
																		)}
																	>
																		{index + 1}
																	</span>
																	{item}
																</li>
															),
														)}
												</ul>
											</AccordionContent>
										</AccordionItem>
									</Accordion>
								</div>
								<Button disabled={isLoading}>
									{isLoading && (
										<i className="bi-arrow-clockwise mr-2  animate-spin" />
									)}
									<span>Register</span>
								</Button>
							</div>
						</form>
						<div className="relative">
							<div className="absolute inset-0 flex items-center">
								<span className="w-full border-t"></span>
							</div>
							<div className="relative flex justify-center text-xs uppercase">
								<span className="bg-background px-1 text-muted-foreground">
									Or continue with
								</span>
							</div>
						</div>
						<div className="flex flex-col space-y-2">
							<Button variant="outline" onClick={loginWithDiscord}>
								<i className="bi-discord me-2 text-blue-600"></i>
								<span>Discord</span>
							</Button>

							<Button
								variant="outline"
								className=""
								onClick={loginWithGoogle}
							>
								<i className="bi-google me-2 text-red-500"></i>
								<span>Google</span>
							</Button>
						</div>
					</div>
					<p className="text-center text-sm text-muted-foreground">
						I have read and understood the&nbsp;
						<a
							className="underline underline-offset-4 hover:text-primary"
							href={links.generalDisclaimer}
							target="_blank"
						>
							General Disclaimers
						</a>
						,
						<br />
						<a
							className="underline underline-offset-4 hover:text-primary"
							href={links.TOS}
							target="_blank"
						>
							Terms of Service
						</a>
						&nbsp;&amp;&nbsp;
						<a
							className="underline underline-offset-4 hover:text-primary"
							href={links.privacyPolicy}
							target="_blank"
						>
							Privacy Policy
						</a>
						.
					</p>
				</div>
			</div>
		</div>
	);
}
